
export function useScreenTabHooks() {
    const hooks: any[] = [];

    const addHook = (name: string, hook: () => any) => {
        hooks.push({ name, hook })
    }
    const invokeHook = (name: string, e?: any) => hooks.filter(h => h.name == name).map(h => h.hook(e))

    const onActivated = (hook: () => any) => addHook('activated', hook)
    const onDeactivated = (hook: () => any) => addHook('deactivated', hook)
    const invokeOnActivated = (hook: () => any) => invokeHook('activated', hook)
    const invokeOnDeactivated = (hook: () => any) => invokeHook('deactivated', hook)

    return {
        onActivated,
        onDeactivated,
        invokeOnActivated,
        invokeOnDeactivated
    }
}